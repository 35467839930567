/* Background decorations */
.background-decor {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 0;
}

.circle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Low opacity */
  border-radius: 50%;
}

.circle-1 {
  width: 5rem;
  height: 5rem;
  top: 10%;
  left: 5%;
  opacity: 0.15;
}

.circle-2 {
  width: 8rem;
  height: 8rem;
  top: 30%;
  left: 40%;
  opacity: 0.1;
}

.circle-3 {
  width: 6rem;
  height: 6rem;
  top: 50%;
  right: 15%;
  opacity: 0.12;
}

.circle-4 {
  width: 3rem;
  height: 3rem;
  top: 20%;
  left: 70%;
  opacity: 0.18;
}

.circle-5 {
  width: 4rem;
  height: 4rem;
  bottom: 20%;
  right: 10%;
  opacity: 0.1;
}

.shape {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0.1;
}

.shape-1 {
  width: 12rem;
  height: 12rem;
  top: 25%;
  left: 60%;
  border-radius: 0;
}

.shape-2 {
  width: 10rem;
  height: 10rem;
  top: 60%;
  left: 20%;
  border-radius: 5px;
}

.shape-3 {
  width: 7rem;
  height: 7rem;
  bottom: 10%;
  left: 50%;
  border-radius: 10px;
}

/* Background Styling */
body {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  background: linear-gradient(135deg, #ffd700, #ff8c00);
  background-attachment: fixed;
  background-size: cover;
  color: white;
}

.bases-home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  background: linear-gradient(to bottom, rgba(255, 215, 0, 0.8), rgba(255, 140, 0, 0.9));
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  z-index: 100;
}

/* The card styles remain the same */
.card {
  background: rgba(255, 215, 0, 0.9);
  backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

.background-decor {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}

.circle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  filter: blur(40px);
  animation: pulse 4s infinite;
}

.circle-1 {
  width: 400px;
  height: 400px;
  top: -100px;
  left: -100px;
}

.circle-2 {
  width: 300px;
  height: 300px;
  top: 25%;
  right: 100px;
  animation-delay: 1s;
}

.circle-3 {
  width: 250px;
  height: 250px;
  bottom: 100px;
  left: 33%;
  animation-delay: 2s;
}

.circle-4 {
  width: 350px;
  height: 350px;
  bottom: -100px;
  right: -100px;
  animation-delay: 1.5s;
}

.circle-5 {
  width: 400px;
  height: 400px;
  top: 50%;
  left: 25%;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 0.1;
  }
}

.shape {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.2); /* Light shape */
  z-index: 0;
}

.shape-1 {
  top: 25%;
  left: 25%;
  transform: rotate(45deg);
}

.shape-2 {
  bottom: 30%;
  right: 10%;
  transform: rotate(90deg);
}

.shape-3 {
  top: 60%;
  right: 10%;
  transform: rotate(135deg);
}


/* Gradient Animation */
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-animation {
  background: linear-gradient(80deg, #FFEB3B, #FFC107, #FF9900);
  background-size: 1000% 1000%;
  animation: gradientBG 1s infinite linear;
}

/* Add smooth typing effect */
.typing-effect {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: typing 4s steps(30) forwards; /* 4s duration, adjust steps to match character count */
}

@keyframes typing {
  to {
    width: 100%; /* Ensures the text reveals letter by letter */
  }
}

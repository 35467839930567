@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Barlow', sans-serif;
}

.custom-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Customize this as needed */
}

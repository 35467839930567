/* Keyframes for image and container animation */
@keyframes growIntoLeft {
    from {
        transform: scale(1) translateX(0);
        width: 40%;  /* Start from a smaller width */
        height: 45%; /* Start from a smaller height */
        border-radius: 15px;
        border: 5px solid white;
    }
    to {
        transform: scale(1) translateX(0);
        width: 768px;  /* Grow to match video player width */
        height: 496px; /* Grow to match video player height */
        border-radius: 8px 8px 0 0;
        border: none;
    }
}

/* Container wrapper */
.wrapper {
    position: relative;
    width: 40%;  /* Start from a smaller width */
    height: 45%; /* Start from a smaller height */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;
}

.wrapper.animating {
    width: 768px;  /* Grow to match video player width */
    height: 496px; /* Grow to match video player height */
    aspect-ratio: 16/9;
    padding: 1.5rem; /* p-6 */
}

/* Image container settings */
.imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    border: 5px solid white;
    border-radius: 15px;
    transition: all 1s ease;
    overflow: hidden;
}

.imageContainer.animating {
    position: absolute;
    animation: growIntoLeft 1s forwards;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

/* Orange filter */
.imageOverlay {
    position: absolute;
    inset: 0;
    background-color: rgba(255, 165, 0, 0.5);
    z-index: 1;
    pointer-events: none;
    border-radius: inherit;
}

/* Image settings */
img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
